$(document).ready(function() {
var date = new Date();
var d = date.getDate();
var m = date.getMonth();
var y = date.getFullYear();
    function calcDate(z,k) {
        var zday = z.getDate();
        var zmonth = z.getMonth()+1;
        var zyear = z.getFullYear();

        var kday = k.getDate();
        var kmonth = k.getMonth()+1;
        var kyear = k.getFullYear();
        var datum="";
        if(zday==kday&&zmonth==kmonth&&zyear==kyear)
            datum=zday+"."+zmonth+"."+zyear;
        else
            datum=zday+"."+zmonth+"."+zyear+" - "+kday+"."+kmonth+"."+kyear;
        return datum;
    }
    function abc(date,cell,lendar,neki){
        var d = new Date(date);
        var day = d.getDate();
        var month = d.getMonth()+1;
        var year = d.getFullYear();
        var abc='';
        lendar.fullCalendar('clientEvents', function(event) {
            event.end -= 3600000;
            var z = new Date(event.start);
            var k = new Date(event.end);
            var zday = z.getDate();
            var zmonth = z.getMonth()+1;
            var zyear = z.getFullYear();
            if( event.start <= date &&  event.end >= date || (zday==day&&zmonth==month&&zyear==year)) {
                //alert(event.title);
                var datum=calcDate(z,k);
                abc+='<div class="'+event.source.className+'"><div class="eventTitle">'+event.title+'</div><div class="eventDate">'+datum+"</div></div>";
                return true;
            }
            return false;
        });

        var cde='text-align: center; width:250px;margin:5px;background:#ccc;position:absolute;z-index:10001;';
        if(abc=='')
            cde='display:none;';
        $("body").append( '<div class="tooltipevent" style="'+cde+'">'+abc+'</div>');
        var tooltip=$('.tooltipevent');
        $(neki).mouseover(function(e) {
            $(neki).css('z-index', 10000);
            tooltip.fadeIn('500');
            tooltip.fadeTo('10', 1.9);
        }).mousemove(function(e) {
            tooltip.css('top', e.pageY + 10);
            tooltip.css('left', e.pageX - 100);
        });
    }
$('.new-calendar').fullCalendar({
    lang: 'sl',
    dayRender: function(date, cell) {
        var lendar = $('.new-calendar');
        $(cell).closest(".fc-row").find(".fc-content-skeleton td").eq($(cell).index()).mouseout(function() {
            //$('#calendarInfo2').html("");
            $(this).css('z-index', 8);
            $('.tooltipevent').remove();
        });
        //console.log($(cell).closest(".fc-row").find(".fc-content-skeleton td").eq($(cell).index()));
        $(cell).closest(".fc-row").find(".fc-content-skeleton td").eq($(cell).index()).mouseover(function() {
            abc(date,cell,lendar,this);

        });
        $(cell)  .mouseover(function() {
            abc(date,cell,lendar,this);
        });


        $(cell).mouseout(function() {
            //$('#calendarInfo2').html("");
            $(this).css('z-index', 8);
            $('.tooltipevent').remove();
        });
    },
    dayClick: function(date, jsEvent, view) {
        // $('#calendarInfo').html("");
        // var d = new Date(date);
        // var day = d.getDate();
        // var month = d.getMonth()+1;
        // var year = d.getFullYear();
        // $('.new-calendar').fullCalendar('clientEvents', function(event) {
        //     var z = new Date(event.start);
        //     var k = new Date(event.end);
        //
        //     var zday = z.getDate();
        //     var zmonth = z.getMonth()+1;
        //     var zyear = z.getFullYear();
        //
        //     var kday = k.getDate();
        //     var kmonth = k.getMonth()+1;
        //     var kyear = k.getFullYear();
        //     if( event.start <= date &&  event.end >= date || (zday==day&&zmonth==month&&zyear==year)) {
        //         //alert(event.title);
        //
        //         var datum="";
        //         if(zday==kday&&zmonth==kmonth&&zyear==kyear)
        //             datum=zday+"."+zmonth+"."+zyear;
        //         else
        //             datum=zday+"."+zmonth+"."+zyear+" - "+kday+"."+kmonth+"."+kyear;
        //         //alert(event.className+" ... "+event.className.length);
        //         //console.log(event);
        //         $('#calendarInfo').append('<div class="'+event.source.className+'"><div class="eventTitle">'+event.title+'</div><div class="eventDate">'+datum+"</div></div>");
        //         return true;
        //     }
        //     return false;
        // });

        // change the day's background color just for fun
        //$(this).css('background-color', 'red');

    },
    contentHeight: 350,
    googleCalendarApiKey: 'AIzaSyCnDUCLe2t5qPOnbcp7lCkrLtksC3OFRBY',
    eventSources: [
        {
            googleCalendarId: '61dks4a87u5oeml7oktu673o18@group.calendar.google.com',
            className: 'razredSesti'
        },
        {
            googleCalendarId: 'shr7duepoargof4jar46aecj30@group.calendar.google.com',
            className: 'razredSedmi'
        },
        {
            googleCalendarId: '11t09iqoua44bibau6bd2quc4g@group.calendar.google.com',
            className: 'razredOsmi'
        },
        {
            googleCalendarId: '99nm2qccpe232v0dgjn73lfkm8@group.calendar.google.com',
            className: 'razredDeveti'
        },
        {
            googleCalendarId: '4j3gqqb38ofp7sembb45055eg0@group.calendar.google.com',
            className: 'razrednaStopnja'
        },
        {
            googleCalendarId: 'lv2tdftbh3riia0gu0ctkojl5c@group.calendar.google.com',
            className: 'tekomvanjaIzZnanja'
        },
        {
            googleCalendarId: 'a32hd64n1328didg51458i8554@group.calendar.google.com',
            className: 'sportnaTekmovanja'
        },
        {
            googleCalendarId: 'oscgskl@gmail.com',
            className: 'solskiDogodki'
        },
        {
            googleCalendarId: 'i24glb49nfemms8v99k1vm3uh4@group.calendar.google.com',
            className: 'solskiDogodki'
        }
    ],
    header: {
        left: 'title',
        center: false,
        right: false,
    },
    eventClick: function(data, event, view) {
        return false;
    },


    eventMouseover: function(calEvent, jsEvent) {
        var z = new Date(calEvent.start);
        var k = new Date(calEvent.end);
        var datum=calcDate(z,k);
        var tooltipp = '<div class="tooltipevent" style="text-align: center; width:250px;margin:5px;background:#ccc;position:absolute;z-index:10001;">' + '<div class="'+calEvent.source.className+'"><div class="eventTitle">'+calEvent.title+'</div><div class="eventDate">'+datum+'</div></div></div>';
        $("body").append(tooltipp);
        var tooltip=$('.tooltipevent');
        $(this).mouseover(function(e) {
            $(this).css('z-index', 10000);
            tooltip.fadeIn('500');
            tooltip.fadeTo('10', 1.9);
        }).mousemove(function(e) {
            tooltip.css('top', e.pageY + 10);
            tooltip.css('left', e.pageX - 105);
        });
    },

    eventMouseout: function(calEvent, jsEvent) {
        $(this).css('z-index', 8);
        $('.tooltipevent').remove();
    }
});
    $('.full-calendar').fullCalendar({
        lang: 'sl',
        googleCalendarApiKey: 'AIzaSyCnDUCLe2t5qPOnbcp7lCkrLtksC3OFRBY',
        eventSources: [
            {
                googleCalendarId: '61dks4a87u5oeml7oktu673o18@group.calendar.google.com',
                className: 'razredSesti'
            },
            {
                googleCalendarId: 'shr7duepoargof4jar46aecj30@group.calendar.google.com',
                className: 'razredSedmi'
            },
            {
                googleCalendarId: '11t09iqoua44bibau6bd2quc4g@group.calendar.google.com',
                className: 'razredOsmi'
            },
            {
                googleCalendarId: '99nm2qccpe232v0dgjn73lfkm8@group.calendar.google.com',
                className: 'razredDeveti'
            },
            {
                googleCalendarId: '4j3gqqb38ofp7sembb45055eg0@group.calendar.google.com',
                className: 'razrednaStopnja'
            },
            {
                googleCalendarId: 'lv2tdftbh3riia0gu0ctkojl5c@group.calendar.google.com',
                className: 'tekomvanjaIzZnanja'
            },
            {
                googleCalendarId: 'a32hd64n1328didg51458i8554@group.calendar.google.com',
                className: 'sportnaTekmovanja'
            },
            {
                googleCalendarId: 'oscgskl@gmail.com',
                className: 'solskiDogodki'
            },
            {
                googleCalendarId: 'i24glb49nfemms8v99k1vm3uh4@group.calendar.google.com',
                className: 'solskiDogodki'
            }
        ],
        dayRender: function(date, cell) {
            var lendar=$('.full-calendar');
            $(cell).closest(".fc-row").find(".fc-content-skeleton td").eq($(cell).index()).mouseout(function() {
                //$('#calendarInfo2').html("");
                $(this).css('z-index', 8);
                $('.tooltipevent').remove();
            });
            $(cell).closest(".fc-row").find(".fc-content-skeleton td").eq($(cell).index()).mouseover(function() {
                abc(date,cell,lendar,this);
            });
            $(cell)  .mouseover(function() {
                abc(date,cell,lendar,this);
            });

            $(cell).mouseout(function() {
                //$('#calendarInfo2').html("");
                $(this).css('z-index', 8);
                $('.tooltipevent').remove();
            });
        },
        eventClick: function(data, event, view) {
            return false;
        },


        eventMouseover: function(calEvent, jsEvent) {
            var z = new Date(calEvent.start);
            var k = new Date(calEvent.end);
            var datum=calcDate(z,k);
            var tooltipp = '<div class="tooltipevent" style="text-align: center; width:250px;margin:5px;background:#ccc;position:absolute;z-index:10001;">' + '<div class="'+calEvent.source.className+'"><div class="eventTitle">'+calEvent.title+'</div><div class="eventDate">'+datum+'</div></div></div>';
            $("body").append(tooltipp);
            var tolltip = $('.tooltipevent');
            $(this).mouseover(function(e) {
                $(this).css('z-index', 10000);
                tolltip.fadeIn('500');
                tolltip.fadeTo('10', 1.9);
            }).mousemove(function(e) {
                tolltip.css('top', e.pageY + 10);
                tolltip.css('left', e.pageX - 105);
            });
        },

        eventMouseout: function(calEvent, jsEvent) {
            $(this).css('z-index', 8);
            $('.tooltipevent').remove();
        }
    });
});

(function(factory) {
    if (typeof define === 'function' && define.amd) {
        define([ 'jquery' ], factory);
    }
    else if (typeof exports === 'object') { // Node/CommonJS
        module.exports = factory(require('jquery'));
    }
    else {
        factory(jQuery);
    }
})(function($) {


    var API_BASE = 'https://www.googleapis.com/calendar/v3/calendars';
    var FC = $.fullCalendar;
    var applyAll = FC.applyAll;


    FC.sourceNormalizers.push(function(sourceOptions) {
        var googleCalendarId = sourceOptions.googleCalendarId;
        var url = sourceOptions.url;
        var match;

        // if the Google Calendar ID hasn't been explicitly defined
        if (!googleCalendarId && url) {

            // detect if the ID was specified as a single string.
            // will match calendars like "asdf1234@calendar.google.com" in addition to person email calendars.
            if (/^[^\/]+@([^\/\.]+\.)*(google|googlemail|gmail)\.com$/.test(url)) {
                googleCalendarId = url;
            }
            // try to scrape it out of a V1 or V3 API feed URL
            else if (
                (match = /^https:\/\/www.googleapis.com\/calendar\/v3\/calendars\/([^\/]*)/.exec(url)) ||
                (match = /^https?:\/\/www.google.com\/calendar\/feeds\/([^\/]*)/.exec(url))
            ) {
                googleCalendarId = decodeURIComponent(match[1]);
            }

            if (googleCalendarId) {
                sourceOptions.googleCalendarId = googleCalendarId;
            }
        }


        if (googleCalendarId) { // is this a Google Calendar?

            // make each Google Calendar source uneditable by default
            if (sourceOptions.editable == null) {
                sourceOptions.editable = false;
            }

            // We want removeEventSource to work, but it won't know about the googleCalendarId primitive.
            // Shoehorn it into the url, which will function as the unique primitive. Won't cause side effects.
            // This hack is obsolete since 2.2.3, but keep it so this plugin file is compatible with old versions.
            sourceOptions.url = googleCalendarId;
        }
    });


    FC.sourceFetchers.push(function(sourceOptions, start, end, timezone) {
        if (sourceOptions.googleCalendarId) {
            return transformOptions(sourceOptions, start, end, timezone, this); // `this` is the calendar
        }
    });


    function transformOptions(sourceOptions, start, end, timezone, calendar) {
        var url = API_BASE + '/' + encodeURIComponent(sourceOptions.googleCalendarId) + '/events?callback=?'; // jsonp
        var apiKey = sourceOptions.googleCalendarApiKey || calendar.options.googleCalendarApiKey;
        var success = sourceOptions.success;
        var data;
        var timezoneArg; // populated when a specific timezone. escaped to Google's liking

        function reportError(message, apiErrorObjs) {
            var errorObjs = apiErrorObjs || [ { message: message } ]; // to be passed into error handlers

            // call error handlers
            (sourceOptions.googleCalendarError || $.noop).apply(calendar, errorObjs);
            (calendar.options.googleCalendarError || $.noop).apply(calendar, errorObjs);

            // print error to debug console
            FC.warn.apply(null, [ message ].concat(apiErrorObjs || []));
        }

        if (!apiKey) {
            reportError("Specify a googleCalendarApiKey. See http://fullcalendar.io/docs/google_calendar/");
            return {}; // an empty source to use instead. won't fetch anything.
        }

        // The API expects an ISO8601 datetime with a time and timezone part.
        // Since the calendar's timezone offset isn't always known, request the date in UTC and pad it by a day on each
        // side, guaranteeing we will receive all events in the desired range, albeit a superset.
        // .utc() will set a zone and give it a 00:00:00 time.
        if (!start.hasZone()) {
            start = start.clone().utc().add(-1, 'day');
        }
        if (!end.hasZone()) {
            end = end.clone().utc().add(1, 'day');
        }

        // when sending timezone names to Google, only accepts underscores, not spaces
        if (timezone && timezone != 'local') {
            timezoneArg = timezone.replace(' ', '_');
        }

        data = $.extend({}, sourceOptions.data || {}, {
            key: apiKey,
            timeMin: start.format(),
            timeMax: end.format(),
            timeZone: timezoneArg,
            singleEvents: true,
            maxResults: 9999
        });

        return $.extend({}, sourceOptions, {
            googleCalendarId: null, // prevents source-normalizing from happening again
            url: url,
            data: data,
            startParam: false, // `false` omits this parameter. we already included it above
            endParam: false, // same
            timezoneParam: false, // same
            success: function(data) {
                var events = [];
                var successArgs;
                var successRes;

                if (data.error) {
                    reportError('Google Calendar API: ' + data.error.message, data.error.errors);
                }
                else if (data.items) {
                    $.each(data.items, function(i, entry) {
                        var url = entry.htmlLink || null;

                        // make the URLs for each event show times in the correct timezone
                        if (timezoneArg && url !== null) {
                            url = injectQsComponent(url, 'ctz=' + timezoneArg);
                        }

                        events.push({
                            id: entry.id,
                            title: entry.summary,
                            start: entry.start.dateTime || entry.start.date, // try timed. will fall back to all-day
                            end: entry.end.dateTime || entry.end.date, // same
                            url: url,
                            location: entry.location,
                            description: entry.description
                        });
                    });

                    // call the success handler(s) and allow it to return a new events array
                    successArgs = [ events ].concat(Array.prototype.slice.call(arguments, 1)); // forward other jq args
                    successRes = applyAll(success, this, successArgs);
                    if ($.isArray(successRes)) {
                        return successRes;
                    }
                }

                return events;
            }
        });
    }


// Injects a string like "arg=value" into the querystring of a URL
    function injectQsComponent(url, component) {
        // inject it after the querystring but before the fragment
        return url.replace(/(\?.*?)?(#|$)/, function(whole, qs, hash) {
            return (qs ? qs + '&' : '?') + component + hash;
        });
    }
});

