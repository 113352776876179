$('.album-item.video').click(function(e){
	e.preventDefault();

	// add
	$('#container').append("<div id='player'><div class='iframe-wrap' style='margin-top: "+ $(window).scrollTop() +"px;'><iframe width='1080' height='608' src='https://www.youtube.com/embed/"+ $(this).attr('video-id') +"?autoplay=true' frameborder='0' allowfullscreen></iframe></div></div>");

	// delete
	$('#player').click(function(e){
		e.preventDefault();
		this.remove();
	});
});

