class oscgMenu {

    constructor() {
        this.leftMenu();
    }

    leftMenu() {
        var menuBox = $('.menu-main');
        let menuRelated = $('.menuRelated');
        let menuDepth = $('.menuDeep');
        let urlPath = window.location.pathname;
        let path = urlPath.split('/');
        path.shift();

        // let pathLength = path.length;
        let currentPath = '';
        let topLevel = '';

        path.pop();
        currentPath = path.join('/');

        let attr = menuBox.find('a[data-defaulturl="/'+ currentPath +'"]');
        if (typeof attr[0] !== typeof undefined && attr[0] !== false) {
            topLevel = attr;
        }
        else {
            let test = urlPath.split('/');
            test.shift();
            test.pop();
            let url = test.join('/');
            
            if(urlPath.match('^/o-soli/knjiznica')) {    // OSCG Hack
                topLevel = menuBox.find('a[href="/o-soli/knjiznica/predstavitev-knjiznice"]:first');
            }
            else {
                topLevel = menuBox.find('a[href="/'+ url +'"]:last');
            }
        }
        this.parseMenu(topLevel, menuRelated);

        let subLevel = menuBox.find('a[href="'+ urlPath +'"]:last');
        this.parseMenu(subLevel, menuDepth);

        this.activeMenu(urlPath);
    }

    parseMenu(data, ele) {
        let html = '<ul>';
        if(data.parent('li').has('ul').length) {
            let items = data.parent('li').find('> ul').find('> li > a');
            $.each(items, (k, val) => {
                html += `<li>${$(val).get(0).outerHTML}</li>`;
            });
        }
        else {
            ele.hide();
        }
        html += '</ul>';
        ele.find('nav').html(html);
    }

    activeMenu(url) {
        $('.sidebar a[href="'+ url +'"]').parent().addClass('selected');
    }

}

$(document).ready(function () {
   new oscgMenu();
});