function searchFormSubmit(inputElement, event) {
	if (event.keyCode == 13) { // No need to do browser specific checks. It is always 13.
		inputElement.form.submit();
	}
}

function obvezniVnos(el){
	$(el).css("border", "1px solid red").effect( "shake" );
	return;
}

//Stackanje tabel
function stackedTables() {
	var tables = $("section.text-page").find("table");

	$.each(tables, function(index, val) {

		var rows = $(this).find('tr:last-of-type');
		var countRows = $(this).find('tr').length;
		var countCells = rows.find('td').length;

		if (countCells < 3) { $(this).addClass("stacked"); }
		if (countCells > 5) { $(this).addClass("smalltext"); }

	});
}

//Podiranje tabov na responsive
function tabsCollapse() {

	$( ".tab-header.tab-collapse" ).each(function() {
		var atWidth = $(this).attr("stacking");
		if ( $(document).width() < atWidth ) { $(this).addClass("collapse"); }
		else { $(this).removeClass("collapse"); }
	});

}

//Responsive za slick-tabs normalen
var responsiveOption1 = [
	{
	breakpoint: 1200,
	settings: {
		slidesToShow: 4,
		slidesToScroll: 1
		}
	},
	{
	breakpoint: 992,
	settings: {
		slidesToShow: 3,
		slidesToScroll: 1
		}
	},
	{
	breakpoint: 769,
	settings: {
		slidesToShow: 1,
		slidesToScroll: 1
		}
	}
];
//Responsive za slick-tabs na product selectorju
var responsiveOption2 = [
	{
	breakpoint: 1200,
	settings: {
		slidesToShow: 1,
		slidesToScroll: 1
		}
	},
	{
	breakpoint: 992,
	settings: {
		draggable: false,
		verticalSwiping: false,
		touchMove: false,
		swipe: false,
		slidesToShow: 1,
		slidesToScroll: 1
		}
	}
];

//Tabs header slicks resizing
function tabsSlick() {

	if ( $( ".slick-tabs" ).length ) {


		var tabHeader = $( ".slick-tabs" ).parents(".tab-header");
		var tabGroup = tabHeader.attr("tab-group");
		var stackWidth = tabHeader.attr("stacking");
		//var tabActive = $(this).attr("tab");

		if ( $(document).width() > stackWidth ) {
			$('.slick-tabs').slick('unslick');
		}
		else {
			if ( $( ".slick-tabs .slick-list" ).length ) {}
			else {
				$('.slick-tabs').slick({
					slidesToShow: 6,
					slidesToScroll: 6,
					asNavFor: '.slick-thumbnails',
					dots: false,
					//adaptiveHeight: true,
					//variableWidth: true,
					arrows: true,
					infinite: false,
					prevArrow: '<div class="nav-arrow nav-prev"></div>',
					nextArrow: '<div class="nav-arrow nav-next"></div>',
					appendArrows: '.tabs-nav',
					responsive: !$(".product-selector-tabs").length ? responsiveOption1 : responsiveOption2
				});
			}

			//Prikazemo screen glede na trenutno aktivni slide - switchanje po screenih je urejeno v product-selector.js
			$( ".slick-tabs" ).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				var i = (currentSlide ? currentSlide : 0) + 1;
				//console.log(i);
				//console.log(tabGroup);
				//console.log("tab-switch"+i);

				//Ce nismo na product selectorju, potem switchanje headerja nastavi aktiven tab content
				if ( !$(".slick-tabs").hasClass("product-selector-tabs") ) {
					$(".tab-content[tab-group="+tabGroup+"]").removeClass("show");
					$(".tab-content[tab-group="+tabGroup+"].tab-switch"+i).addClass("show");
				}

				console.log("aktivni slide: " + i);

				$(".tab-header[tab-group="+tabGroup+"] .tab-switcher").removeClass("active");
				var tabSwitch = "tab-switch";
				$(".tab-header[tab-group="+tabGroup+"] .tab-switcher[tab="+tabSwitch+i+"]").addClass("active");
			});
		}

	}

}

//Modal gallery - prilagajanje po visini
function modalGalleryHeight() {
	if ( $('.modal-gallery-wrapper .slick-gallery').length ) {

		//Vars
		var modal = $(".modal-gallery-wrapper");
		var modalGalleryWrapper = $('.modal-gallery-wrapper .general-gallery-wrapper');
		var modalGalleryTrack = $('.modal-gallery-wrapper .general-gallery-wrapper .slick-track');
		var modalGalleryItem = $('.modal-gallery-wrapper .general-gallery-wrapper .slick-item');
		var modalCaption = $(".modal-gallery-wrapper .modal-caption");
		var modalThumbsWrapper = $('.modal-gallery-wrapper .general-thumbs-wrapper');

		var galleryHeight = modalGalleryWrapper.outerHeight();
		var contentHeight = parseInt(modalCaption.outerHeight()) + parseInt(modalCaption.css("margin-bottom")) ;
		var thumbsHeight = modalThumbsWrapper.outerHeight();
		var paddings = parseInt( $(".modal-gallery-wrapper .noscroll").css("padding-top") ) + parseInt( $(".modal-gallery-wrapper .noscroll").css("padding-bottom") );
		var screenHeight = $( window ).height();

		var availableHeight = screenHeight - paddings - thumbsHeight - contentHeight;

		modalGalleryItem.css("height", availableHeight);
		modalGalleryItem.addClass("setHeight");
		modalGalleryTrack.css("height", availableHeight);

		//$('.modal-gallery-wrapper .slick-gallery').resize()
		//$('.modal-gallery-wrapper .slick-thumbnails').resize();

		$('.modal-gallery-wrapper .slick-gallery').get(0).slick.setPosition();
		$('.modal-gallery-wrapper .slick-thumbnails').get(0).slick.setPosition();
		//galleryModal();
	}
}

//Pozicioniranje splošnega modalnega okna
function positionModalWindow() {
	if ( $( ".modal-window-outer" ).length ) {

		var modalItems = $(".modal-window-outer .modal-items");
		var modalTitle = $(".modal-window-outer .modal-title").outerHeight();
		var modalFooter = $(".modal-window-outer .modal-footer").outerHeight();
		var screenHeight = $( window ).height();
		var paddings = 60; //top and bottom 2x30px - default padding

		//Drugacen padding za product compare na detail podstraneh v mobile pogledu pod 480px
		if ( $(".modal-window").hasClass("compare-models") && $(document).width() < 480) { paddings = 0; }

		//Ce ima modalno okno selected model
		if ( $( ".modal-window-outer .selected-model" ).length ) {
			var selectedModel = $(".modal-window-outer .selected-model").outerHeight();
			var availableHeight = screenHeight - paddings - modalTitle - modalFooter - selectedModel;
		}
		else {
			var availableHeight = screenHeight - paddings - modalTitle - modalFooter;			
		}

		//console.log(availableHeight);
		modalItems.css("max-height", availableHeight);

	}
}

//plugin modalnemu oknu za body
function positionBodyforMW() {
	$("html").toggleClass("noscroll");	
}


//Fixed header - samo za desktop view
function fixedHeader() {

	if ( $( window ).width() > 992 ) {

		if($(document).scrollTop() > 90) {
			$("body").addClass("fixnav");
			//$(".header-main").css('position', 'fixed');
		}
		else {
			$("body").removeClass("fixnav");
			$("body").removeClass("header-top");
			$('.openmenu').removeClass("open");
		}
	}
}

//Pod boostrapovih 992px preklopimo v mobile view
function mobileView() {
	if ( $( window ).width() < 993 ) {
		$(".header-main .menu-main").removeClass("open");
		$(".header-main .search").removeClass("open");
		$('.header-main .openmenu').removeClass("open");
		$("html").removeClass("noscroll");
		$("body").addClass("mobile-view");
		$("body").removeClass("desktop-view");
		$("body").removeClass("fixnav");
	}
	else {
		$(".header-main .menu-main").removeClass("open");
		$(".header-main .search").removeClass("open");
		$('.header-main .openmenu').removeClass("open");
		$("html").removeClass("noscroll");
		$("body").removeClass("mobile-view");
		$("body").addClass("desktop-view");
	}
}

//Mobile menu: racuna visino containerja in nastavi overflow ce je vec contenta kot je visine
function level2Height() {
	var vH = $( window ).height();
	var hH = $(".header-main").outerHeight();
	var sH = $(".header-main .search").outerHeight();
	var mH = vH - hH - sH;
	//console.log(hH);
	//Racunamo samo ce smo v mobile view in ce je menu odprt
	if ( $("body").hasClass("mobile-view") && $(".menu-main").hasClass("open") ) {
		$(".menu-main > ul").css("max-height", mH);
	}
}

//Nastavimo visino slideshowa relativno na sirino okna
function setSlide() {
	if ( $("body").hasClass("mobile-view") ) {
		//Nastavi visino slideshowa glede na visino slike v njem -za desktop
		var slideHeight = $( window ).width();
		var slideHeight = slideHeight / 1.5;
		var slideHeight = Math.floor(slideHeight);
		$("div.module-slideshow.home").css("height", slideHeight + "px");
	}
	else {
		$("div.module-slideshow.home").removeAttr("style");
	}
};

//Floater - generalni
function floaterScroll() {

	//nekje mu narobe hodi 60px pa ne morem ugotovit zakaj...treba preverit
	if ( $(".floater").length && $('.floater-start').length) {
		
		var floater = $(".floater");

		if ( $( window ).width() > 992 ) {

			floater.removeClass("responsive");

			//Offsets (ce se jih implementira)
			var offsetTop = floater.attr("offsetTop") ? parseInt(floater.attr("offsetTop")) : 0;
			var offsetBottom = floater.attr("offsetBottom") ? parseInt(floater.attr("offsetBottom")) : 0;

			if ($(".sidebar-wrapper").length) { var sidebarWrapper = $(".sidebar-wrapper"); } //Smo v download centru
			if ($("#selectorRightBox").length) { var sidebarWrapper = $("#selectorRightBox"); } //smo v product selectorju

			//Dodamo zamik v vrednosti padding-left (15) za download center
			var colPadding = $("#products-results").length ? 15 : 0;

			var currentTop = $(window).scrollTop();
			var startPos = parseInt( $(".floater-start").offset().top );
			var fromLeft = parseInt( sidebarWrapper.offset().left ) + colPadding;
			var floaterWidth = sidebarWrapper.width();
			var floaterHeight = parseInt(floater.outerHeight());
			var endPos = parseInt($(".floater-end").offset().top) + parseInt($(".floater-end").outerHeight()) - floaterHeight;

			endPosEnd = endPos - floaterHeight;
			currentTop = currentTop + offsetTop;

			//console.log("Start: " + startPos);
			//console.log("Stop: " + floaterHeight);
			//console.log("Current top: " + currentTop);
			//console.log("floaterHeight: " + floaterHeight);
			//console.log("Endpos: " + endPos);

			//Smo na product compare 
			if ( $("#products-results").length && !$("#products-results").children().length > 0 ) {
				floater.removeAttr("style");
				floater.css("position", "relative");
			}
			//Smo na download centru
			else {
				//Vozimo
				if ( currentTop > startPos && currentTop < endPos ) {
					floater.removeAttr("style").removeClass("fixed").addClass("floating");
					floater.css( {"top": currentTop - startPos , "width": floaterWidth} );
					//floater.css( {"left": fromLeft, "top": offsetTop + currentTop - startPos, "width": floaterWidth} );
				}
				//Zasidramo
				else if ( currentTop > endPos ) {
					//floater.removeAttr("style floating").addClass("fixed");
					//floater.css( {"top": endPosEnd - offsetTop, "width": floaterWidth} );
				}
				//Smo nazaj na vrhu
				else {
					floater.removeAttr("style");
					floater.removeClass("floating fixed");
				}
				
			}


		}
		else {
		// 	//$(".floater .opener").click(function(){
		// 	$(".floater .opener").on('click', function(e) {
		// 		floater.toggleClass("open");
		// 		console.log("odpiram floater");
		// 	})
			floater.addClass("responsive");
		}
	}

}

$(document).ready(function() {

	//Debug za mobile
	// setInterval(function(){ 
	// 	$(".debug").html( $( window ).width() + " / " + $("body")[0].classList );
	// }, 500);

	//iPad air 2nd level main menu click
	//$('.ipad-click a').on('click touchend', function(e) {

	//Match height
	$(function() {
		$('.equal-height').matchHeight({
			byRow: true,
			property: 'height',
			target: null,
			remove: false
		});
	});

	//Reset checkboxov za mobilni meni
	$(".mobile-menu input[type='checkbox']").each(function() {
		//$(this).attr('checked', false);
		$(this).click(function(){
			level2Height();
		})
	});
	$("#main-nav-check").attr('checked', false);

	$("#main-nav-check").change(function() {
		if(this.checked) {
			$("html").addClass("noscroll");
		}
		else {
			$("html").removeClass("noscroll");
		}
	});

	//Zapremo glavni meni ko se klikne na container
	$('#container').click(function(){
		if($("#main-nav-check").is(":checked")) {
			$("#main-nav-check").prop('checked', false);
			$(".mobile-menu input[type='checkbox']").prop('checked', false);
		}
	});

	//Placeholder fallback
	if ( !("placeholder" in document.createElement("input")) ) {
		$("input[placeholder], textarea[placeholder]").each(function() {
			var val = $(this).attr("placeholder");
			if ( this.value == "" ) {
				this.value = val;
			}
			$(this).focus(function() {
				if ( this.value == val ) {
					this.value = "";
				}
			}).blur(function() {
				if ( $.trim(this.value) == "" ) {
					this.value = val;
				}
			})
		});

		// Clear default placeholder values on form submit
		$('form').submit(function() {
			$(this).find("input[placeholder], textarea[placeholder]").each(function() {
				if ( this.value == $(this).attr("placeholder") ) {
					this.value = "";
				}
			});
		});
	}

	if ( $( window ).width() > 992) {
		$('.ipad-click a').on('touchend', function(e) {
			var el = $(this);
			var link = el.attr('href');
			//if(el.attr("target") != "_blank")
			if(e.which != 2){
				if(el.attr("target") != "_blank"){
					window.location = link;
				}
			}
		});
	}

	if ( $( ".slick-slideshow" ).length ) {
		$('.slick-slideshow').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			//asNavFor: '.slick-thumbnails',
			dots: true,
			//adaptiveHeight: true,
			//variableWidth: true,
			arrows: true,
			infinite: false,
			prevArrow: '<div class="nav-arrow nav-prev"></div>',
			nextArrow: '<div class="nav-arrow nav-next"></div>',
			appendArrows: '.slideshow-nav'
		});
	}

	if ( $( ".slick-features" ).length ) {
		$('.slick-features').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			//asNavFor: '.slick-thumbnails',
			dots: false,
			//adaptiveHeight: true,
			//variableWidth: true,
			arrows: true,
			infinite: false,
			prevArrow: '<div class="nav-arrow nav-prev"></div>',
			nextArrow: '<div class="nav-arrow nav-next"></div>',
			appendArrows: '.features-nav',
			responsive: [
				{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
					}
				},
				{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
					}
				},
				{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
					}
				},
				{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
					}
				}
			]
		});
	}

	//Back button
	$('a.back').click(function(){
		if( $(this).attr("rel") ) {
			window.location = $(this).attr("rel");
		}
		else {
			parent.history.back();
			return false;
		}
	});

	//Product tabs accordion - jqueryUI
	// $(function() {
	// 	$( ".accordion" ).accordion({
	// 		collapsible: true,
	// 		heightStyle: "content",
	// 		active: false
	// 	});
	// });

	//Menu dropdown toggler
	$('.menu-dropdown').click(function(){
		$(this).find(".drop-selection").toggleClass("open", 500);

	})

	//Hamburger menu prikaze header
	$('.menu-container .openmenu').click(function(){

		//Nastavimo menu open/closed text za meni
		// var menuText = $(this).find("p");
		// var closeText = menuText.attr("txtClose");
		// var openText = menuText.attr("txtOpen");
		// if ( $(this).hasClass("open") ) { menuText.html(openText); }
		// else { menuText.html(closeText); }

		//Resetiramo vse classe - ponastavimo meni na prvotno stanje
		$(".header-main .menu-main").removeClass("swipe-left-100");
		$(".header-main .menu-main").removeClass("swipe-left-200");
		$(".header-main .menu-main li.open").removeClass("open");
		$(".menu-main .menu-main-levels.ranges").removeClass("overflow");
		//Toggli za mobile menu
		$(".header-main .menu-main").toggleClass("open");
		$(".header-main .search").toggleClass("open");
		$(this).toggleClass("open");
		//na body nastavimo noscroll (overflow:hidden) da preprecimo scroll po strani (ostane scroll po meniju)
		$("html").toggleClass("noscroll");
		level2Height();
	})


	$(document).mouseup(function (e)
	{
		var container2 = $('.faker');
		if (!container2.is(e.target) && container2.has(e.target).length === 0) { container2.removeClass("open", 200); }
	});

	//Mobile menu - odpiranje drugega nivoja, zamanknemo glavni meni v levo za 100%
	$('.menu-main b.level1-toggle').click(function(){
		$(this).parent().addClass("open");
		$('.menu-main').addClass("swipe-left-100");
	})

	//Mobile menu - gremo nazaj iz drugega nivoja v prvi, glavni meni se premakne v zacetno pozicijo
	$('.menu-main .menu-main-levels > .menu-title').click(function(){
		$('.menu-main').removeClass("swipe-left-100");
		//Delay zaradi animacije. Po 300ms skrijemo glavni meni, potem lahko zapremo drugi nivo, da se ne overlapa
		//setTimeout(function(){
			$('.menu-main li').removeClass("open");
		//}, 310);
	})

	//Mobile menu -odpiranje tretjega nivoja, glavni meni v levo za 200% (100% za prvi nivo, 100% za drugi)
	$('.menu-main b.level2-toggle').click(function(){
		$(".menu-main .menu-main-levels.ranges").toggleClass("overflow");
		$(this).parent().toggleClass("open");
		$('.menu-main').removeClass("swipe-left-100");
		$('.menu-main').addClass("swipe-left-200");
	})

	//Mobile menu - gremo nazaj iz tretjega nivoja v drugi
	$('.menu-main .menu-main-levels .menu-level3 .menu-title').click(function(){
		$('.menu-main').removeClass("swipe-left-200");
		$('.menu-main').addClass("swipe-left-100");
		//Delay zaradi animacije. Ce zapremo tretji nivo takoj, ima parent "overflow hidden" in
		//se content div skrije, pred koncem animacije
		//setTimeout(function(){
			$('.menu-main .menu-level2 li').removeClass("open");
			$(".menu-main .menu-main-levels.ranges").toggleClass("overflow");
		//}, 310);
	})

	//Glavni meni: racunamo visino tretjega nivoja
	$('.menu-main li.level1').mouseover(function(){
		//samo za desktop
		if ( $("body").hasClass("desktop-view") ) {
			$( ".menu-main li.level1 .menu-level2 ul" ).each(function() {
				var njegovChild = $( this ).find(".menu-level3");
				var njegovChildHeight = njegovChild.actual("height");
				//console.log(njegovChildHeight);
				$( this ).css("min-height", njegovChildHeight);
			});
		}
	})

	//Glavni meni: prikazujemo tretji meni za motohromes na hover levela2
	$('.menu-main li.menu-motorhomes .menu-level2 li').mouseover(function(){
		if ( $("body").hasClass("desktop-view") ) {
			$( ".menu-main li.menu-motorhomes .menu-level2 li .menu-level3" ).each(function() {
				$(this).css("display", "none");
			});
			$(this).find(".menu-level3").css("display", "block");
		}
	});
	//Odstranimo display:block za tretji meni motorhomes in ga tako nastavimo na default vrednost (viden prvi)
	$('.menu-main li.menu-motorhomes .menu-main-levels').mouseleave(function(){
		if ( $("body").hasClass("desktop-view") ) {
			$( ".menu-main li.menu-motorhomes .menu-level2 li" ).each(function() {
				$(this).find(" .menu-level3").removeAttr("style");
			});
		}
	});


	//Smooth scroll to section from anchor links z upostevanjem body paddinga
	// $('.menu-anchor a[href*=#], div.product-controls a[href*=#tour360]').click(function(){
    //
	// 	var paddTop = parseInt($(".menu-range").css("height"));
    //
	// 	if ( $("body").hasClass('header-top') ) {
	// 		paddTop = paddTop + parseInt($(".header-main").css("height"));
	// 	}
	//
	// 	if ( $(".range-anchors").length ) {
	// 		paddTop = paddTop + parseInt($(".range-anchors").css("height")) - 1;
	// 	}
	//
	// 	//var paddTop = parseInt($("body").css("padding-top"));
	// 	$('html, body').animate({
	// 		scrollTop: $( $.attr(this, 'href') ).offset().top-paddTop
	// 	}, 500);
	// 	return false;
	// });

	//Tab swithcer
	$('.tab-header .tab-switcher').click(function(){

		var tabHeader = $(this).parents(".tab-header");

		var tabGroup = tabHeader.attr("tab-group");
		var tabActive = $(this).attr("tab");

		$(".tab-header[tab-group="+tabGroup+"] .tab-switcher").each(function() {
			$(this).removeClass("active");
			$(".tab-content[tab-group="+tabGroup+"]").removeClass("show");
		});
		$(this).addClass("active");
		$(".tab-content[tab-group="+tabGroup+"]."+tabActive).addClass("show");

		//Ce se tabi podirajo (collapse) potem jih na klik skrijemo vse ostale
		if ( tabHeader.hasClass("tab-collapse") ) {
			var atWidth = tabHeader.attr("stacking");
			if ( $(document).width() < atWidth ) {
				tabHeader.addClass("collapse");
			}
			else {}
		}

	});

	//Tab switcher collapse
	tabsCollapse();
	$('.tab-header .tab-opener').click(function(){

		var tabHeader = $(this).parents(".tab-header");
		tabHeader.toggleClass('collapse')

	});
	
	// Contact us for UK for now
	$("#contact-form .submit button").each(function(){
		$(this).click(function(e){
				
			e.preventDefault();
			e.stopPropagation();
			
			var tst = false;
			$("#contact-form input[type='text']").each(function(){
					
					$(this).parents(".input").removeClass("invalid");
					if($(this).nextAll("span")) $(this).nextAll("span").hide();
					
					if(!$(this).val()){
							$(this).parents(".input").addClass("invalid");
							tst = true;
							if($(this).nextAll("span")) $(this).nextAll("span").show();
					}
			});
// 			if(!$("#contact-form input[name='service']").val() && !$("#contact-form input[name='dealer']").val()) tst = true;
			
			
			console.log(tst);
			if(!tst){
					
					
				jQuery.ajax({
					url: redirectHandler+'application/adria/request/subscription/contact_us.php',
					type: 'POST',
					data: {
						email: $("#contact-form input[name='email']").val(),
						firstname: $("#contact-form input[name='firstname']").val(),
						lastname: $("#contact-form input[name='lastname']").val(),
						phone: $("#contact-form input[name='phone']").val(),
						msg: $("#contact-form textarea").val(),
					},
					cache: false,
					scriptCharset: "utf-8",
					success: function(data){
						
						console.log(data);
						
						if(data == "ok"){
								
								$("#contact-form input[type='text']").each(function(){ $(this).val(""); });
								$("#contact-form textarea").each(function(){ $(this).val(""); });
								modal({
									type: "primary",
									title: $("#newsletterMsg").val(),
									text: $("#contactusMsg").val(),
									buttons: [{
										text: "OK",
										val: "ok",
										onClick: function(dialog){
											dialog.close();
										}
									}]
								});

						}
					}
				});
			}
		});
	});


	//Tabs header slider
	if ( $( ".slick-tabs" ).length ) {
		$('.slick-tabs').slick({
			slidesToShow: 6,
			slidesToScroll: 6,
			//asNavFor: '.slick-thumbnails',
			dots: false,
			//adaptiveHeight: true,
			//variableWidth: true,
			arrows: true,
			infinite: false,
			prevArrow: '<div class="nav-arrow nav-prev"></div>',
			nextArrow: '<div class="nav-arrow nav-next"></div>',
			appendArrows: '.tabs-nav',
			responsive: !$(".product-selector-tabs").length ? responsiveOption1 : responsiveOption2
		});
		tabsSlick();
	}

	//Form faker dropdown
	$(".faker div.drop-value").click(function() {
		//selection = $(this).parents(".faker").find('.drop-selection');
		//selection.toggleClass('open', 500);
		var faker = $(this).parents(".faker");
		if ( faker.hasClass('open') ) {
			$(".faker").removeClass("open");
		}
		else {
			$(".faker").removeClass("open");
			faker.addClass('open', 500);
		}
		//selection.fadeIn("slow");
	});

	$(".faker div.drop-selection div").click(function() {
		//Nastavimo faker value
		thisFaker = $(this).parents(".faker");
		thisVal = $(this).attr("rel");
		dropValue = $(this).parents(".faker").find('.drop-value');
		dropValue.html($(this).html());

		thisFaker.find("div.drop-selection div").removeClass('active');
		$(this).addClass("active");

		//Izberemo select option glede na izbran faker value
		$(this).parents(".faker").find("select option").removeAttr('selected');
		$(this).parents(".faker").find("select option[value="+thisVal+"]").attr('selected','selected');

		$(this).parents(".faker").find("select").trigger("change");
		//$(thisFaker + ' select option[value='+thisVal+']').attr('selected','selected');

		//Zapremo dropdown
		thisFaker.removeClass('open');
		//$(this).parents(".faker").find(".drop-selection").toggleClass('open', 500);
		//$(this).parents(".faker").find(".drop-handle").toggleClass('open');
	});

	//Form checkbox toggle
	$( "div.checker.checkbox span" ).click(function() {

		var checkbox = $(this).parent().find("input.checkbox");

		if ( $(this).hasClass("checked") ) {
			checkbox.attr('checked', false);
			$(this).removeClass("checked");
		}
		else {
			checkbox.attr('checked', true);
			$(this).addClass("checked");
		}
	});

	//Form radio button toggle
	$( "div.checker.radio span.radioCheck" ).click(function() {

		var radioGroup = $(this).parents("div.checker");
		var radioButton = $(this).parents("div.checker").find("input[type='radio']");
		var radioID = $(this).attr("radio");

		if ( $(this).hasClass("checked") ) {
			//radioGroup.find("input.radiobutton").attr('checked', false);
			//radioGroup.find("span.radioCheck").removeClass("checked");
			//radioButton.attr('checked', false);
		}
		else {
			radioGroup.find("input[type='radio']").attr('checked', false);
			radioGroup.find("span.radioCheck").removeClass("checked");
			//radioButton.attr('checked', true);
			$(this).parents("div.checker").find("input[type='radio']#"+radioID).attr('checked', true);
			$(this).addClass("checked");
		}
	});

	//Za onsite editing
	function calculateDistance(elem, mouseX, mouseY) {
		return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.offset().left + (elem.width() / 2)), 2) + Math.pow(mouseY - (elem.offset().top + (elem.height() / 2)), 2)));
	}
	//marko dreva onsite
	var mX, mY, distance;
	$(document).mousemove(function (e) {
		mX = e.pageX;
		mY = e.pageY;
		
		$('.image_wrapper, .image_wrapper2').each(function (index, value) {
			var val = $(value)
			distance = calculateDistance(val, mX, mY);
			if (distance < 350) {
				val.addClass('upload_closer');
			}
			else {
				val.removeClass('upload_closer');
			}
		});
		
	});
	
	//Slick Carousel
	function galleryNormal() {
		if ( $( ".slick-gallery" ).length ) {
			$('.slick-gallery').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				asNavFor: '.slick-thumbnails',
				dots: false,
				//adaptiveHeight: true,
				//variableWidth: true,
				arrows: true,
				infinite: false,
				prevArrow: '<div class="nav-arrow nav-prev"></div>',
				nextArrow: '<div class="nav-arrow nav-next"></div>',
				appendArrows: '.gallery-nav'
			});

			$('.slick-thumbnails').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				asNavFor: '.slick-gallery',
				centerMode: false,
				dots: false,
				arrows: true,
				infinite: false,
				focusOnSelect: true,
				prevArrow: '<div class="nav-arrow nav-prev"></div>',
				nextArrow: '<div class="nav-arrow nav-next"></div>',
				appendArrows: '.thumbs-nav'
			});
		}
	}

	function galleryModal() {
		if ( $( ".modal-gallery-wrapper .slick-gallery" ).length ) {
			$('.modal-gallery-wrapper .modal-gallery-placeholder .slick-gallery').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				asNavFor: '.modal-gallery-wrapper .slick-thumbnails',
				dots: false,
				//adaptiveHeight: true,
				//variableWidth: true,
				arrows: true,
				infinite: false,
				adaptiveHeight: true,
				prevArrow: '<div class="modal-nav nav-prev"></div>',
				nextArrow: '<div class="modal-nav nav-next"></div>',
				appendArrows: '.gallery-nav'
			});
			$('.modal-gallery-wrapper .slick-thumbnails').slick({
				slidesToShow: 6,
				slidesToScroll: 6,
				asNavFor: '.modal-gallery-wrapper .slick-gallery',
				centerMode: false,
				dots: false,
				arrows: true,
				infinite: false,
				focusOnSelect: true,
				prevArrow: '<div class="nav-arrow nav-prev"></div>',
				nextArrow: '<div class="nav-arrow nav-next"></div>',
				appendArrows: '.thumbs-nav'
			});
		}
	}

	galleryNormal();

	//Gallery modal open
	$( "#container .general-gallery .slick-gallery .slick-item" ).click(function() {

		var modal = $(".modal-gallery-wrapper");
		//var modalImages = $(".modal-gallery-wrapper .modal-gallery-placeholder");
		modal.addClass('show');

		//$(gallery).appendTo(modalImages);

		//Podremo galerijo za lažji prenos in jo skopiramo v modalno okno
		var galleryPlaceholder = $(this).parents(".general-gallery-placeholder");
		var galleryID = galleryPlaceholder.attr("gallery-id");
		var galleryGallery = galleryPlaceholder.find(".slick-gallery");
		var galleryThumbs = galleryPlaceholder.find(".slick-thumbnails");

		var currentSlide = galleryGallery.slick("slickCurrentSlide"); //Dobimo trenutni slide

		galleryGallery.slick("unslick");
		galleryThumbs.slick("unslick");

		var galleryGalleryHTML = galleryGallery.parent().html();
		var galleryThumbsHTML = galleryThumbs.parent().html();

		$('.modal-gallery-wrapper .general-gallery-wrapper').html(galleryGalleryHTML);
		$('.modal-gallery-wrapper .general-thumbs-wrapper').html(galleryThumbsHTML);

		//Podremo navigacijo, ki jo kasneje spet uporabimo
		var galleryNav = galleryGallery.next(".gallery-nav");
		var oldClass = galleryNav.attr('class');
		galleryNav.attr('oldclass', oldClass);
		galleryNav.removeAttr('class');
		galleryNav.addClass("old-nav");
		
		//Reinit galerije
		$('.modal-gallery-wrapper .modal-gallery-placeholder .slick-gallery').attr("gallery-id", galleryID);
		galleryModal();
		
		//Nastavimo aktivni slide in overflow na body, da preprecimo scrollanje
		$('.modal-gallery-wrapper .modal-gallery-placeholder .slick-gallery').slick('slickGoTo', currentSlide, true);
		$('.modal-gallery-wrapper .modal-thumbnails-placeholder .slick-gallery').slick('slickGoTo', currentSlide, true);
		$("html").addClass("noscroll");

		modalGalleryHeight();

		//Stevilcenje slidov v modalcu
		var modalGallery = modal.find(".slick-gallery");
		var modalThumbs = modal.find(".slick-thumbnails");
		var galleryCounter = modal.find(".modal-caption .modal-caption-number p");
		var galleryCaptionContainer = modal.find(".modal-caption .modal-caption-text");
		var galleryCountTotal = modalGallery.find(".slick-item").length;

		//Nastavimo stevec in caption text za trenutni slide
		galleryCounter.html( (currentSlide + 1) + "/" + galleryCountTotal );
		var currentSlideCaption = modalGallery.find(".slick-item").eq(currentSlide);
		galleryCaptionContainer.html( currentSlideCaption.find('.item-caption').html() );
		
		modalThumbs.find(".slick-thumb").removeClass('slick-current');
		modalThumbs.find(".slick-thumb").eq(currentSlide).addClass('slick-current');

		//Osvezimo stevec, caption in aktivne thumbe ko menjamo slide
		modalGallery.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			var i = (currentSlide ? currentSlide : 0) + 1;
			galleryCounter.text(i + '/' + slick.slideCount);
			//Caption text za slideshowe
			galleryCaptionContainer.html( $(slick.$slides.get(i-1)).find('.item-caption').html() );
			//Oznacimo aktivni thumb
			modalThumbs.find(".slick-thumb").removeClass('slick-current');
			modalThumbs.find(".slick-thumb").eq(i-1).addClass('slick-current');
		});

		//Ponovno reInit galerije, da sprozimo .on event zgoraj (za stevec in caption)
		//$('.modal-gallery-wrapper .modal-gallery-placeholder .slick-gallery').slick("unslick");
		//$('.modal-gallery-wrapper .modal-thumbnails-placeholder .slick-gallery').slick("unslick");
		//galleryModal();

	});

	//Gallery modal close
	function modalClose() {

		var modal = $(".modal-gallery-wrapper");
		var gallery = $(modal).find(".slick-gallery");
		var galleryID = gallery.attr("gallery-id");
		var currentSlide = gallery.slick("slickCurrentSlide"); //Dobimo trenutni slide
		galleryPlaceholder = $(".general-gallery-placeholder[gallery-id="+galleryID+"]");
		newGallery = galleryPlaceholder.find(".slick-gallery");
		newThumbs = galleryPlaceholder.find(".slick-thumbnails");

		//Zgradimo navigacijo
		galleryPlaceholder.find(".general-gallery-wrapper").append("<div class='slick-nav gallery-nav'></div>");
		galleryPlaceholder.find(".general-thumbs-wrapper").append("<div class='slick-nav thumbs-nav'></div>");
		//newGalleryNav = galleryPlaceholder.find(".old-nav");
		//var oldClass = newGalleryNav.attr('oldclass');
		//newGalleryNav.attr('class', oldClass);
		//newGalleryNav.removeAttr('oldclass');

		//Spraznimo containerje galerij v modalcu
		modal.find(".general-gallery-wrapper").empty();
		modal.find(".general-thumbs-wrapper").empty();

		//Ce imamo slider galerijo naredimo reinit (prepoznamo jo po IDju)
		if (galleryID) {
			//Reinit galerije v placeholderju
			galleryNormal();

			//Nastavimo aktivni slide in zapremo modalca
			newGallery.slick('slickGoTo', currentSlide, true);
			newThumbs.slick('slickGoTo', currentSlide, true);
		};

		$("html").removeClass("noscroll");
		modal.removeClass("show");

	}

	$( ".modal-gallery-wrapper .modal-close").click(function() { modalClose(); });

	//Modal gallery - navigacija z tipkami
	$(document).keyup(function(e) {
		if ( $( ".modal-gallery-wrapper .slick-gallery" ).length ) {
			if (e.keyCode == 27) modalClose(); //Esc
			if (e.keyCode == 39) $(".modal-gallery-wrapper .slick-gallery").slick('slickNext'); //Naprej
			if (e.keyCode == 37) $(".modal-gallery-wrapper .slick-gallery").slick('slickPrev'); //Nazaj
		}
	});
	
	var locked_slick = false;
	$('.slick-features').on('swipe', function(){ locked_slick = true; });
	
	//Single gallery modal
	$( "img[gallery]" ).each(function( index ) {
		
		
		$(this).click(function() {
			
			if(locked_slick) locked_slick = false;
			else {
				
				var identificator = $(this).attr("src");
				var galleryID = $(this).attr('gallery');
				var currentSlide;

				//Vars
				var modal = $(".modal-gallery-wrapper");
				var modalGalleryWrapper = $('.modal-gallery-wrapper .general-gallery-wrapper');
				var modalThumbsWrapper = $('.modal-gallery-wrapper .general-thumbs-wrapper');

				//Loop cez vse slike, zbuildamo html za galerijo in thumbe
				var modalGalleryHTML = "<div class='slick-gallery'>";
				$( "img[gallery="+galleryID+"]" ).each(function( index ) {
					modalGalleryHTML += "<div class='slick-item'>";
					modalGalleryHTML += this.outerHTML.replace('src=', 'rel=').replace('name=', 'src=');
					modalGalleryHTML += "</div>";
					
					if($(this).attr("src") == identificator) currentSlide = index;
				});
				modalGalleryHTML += "</div>";

				var modalThumbsHTML = "<div class='slick-thumbnails'>";
				$( "img[gallery="+galleryID+"]" ).each(function( index ) {
					modalThumbsHTML += "<div class='slick-thumb'>";
					modalThumbsHTML += this.outerHTML;
					modalThumbsHTML += "</div>";
				});
				modalThumbsHTML += "</div>";

				//Zgradimo carousel v modalcu in pripnemo html za navigacijo
				modalGalleryWrapper.html(modalGalleryHTML);
				modalGalleryWrapper.append("<div class='slick-nav gallery-nav'>");
				modalThumbsWrapper.html(modalThumbsHTML);
				modalThumbsWrapper.append("<div class='slick-nav thumbs-nav'>");

				//prikazemo modalc
				modal.addClass('show');

				galleryModal();

				//console.log("trenutni slide " + currentSlide);
				$('.modal-gallery-wrapper .modal-gallery-placeholder .slick-gallery').slick('slickGoTo', currentSlide, true);
				$('.modal-gallery-wrapper .modal-thumbnails-placeholder .slick-thumbnails').slick('slickGoTo', currentSlide, true);
				$("html").addClass("noscroll");

				modalGalleryHeight();

				//Stevilcenje slidov v modalcu
				var modalGallery = modal.find(".slick-gallery");
				var modalThumbs = modal.find(".slick-thumbnails");
				var galleryCounter = modal.find(".modal-caption .modal-caption-number p");
				var galleryCaptionContainer = modal.find(".modal-caption .modal-caption-text");
				var galleryCountTotal = modalGallery.find(".slick-item").length;

				//Nastavimo stevec in caption text za trenutni slide
				galleryCounter.html( (currentSlide + 1) + "/" + galleryCountTotal );
				var currentSlideCaption = modalGallery.find(".slick-item").eq(currentSlide);
				galleryCaptionContainer.html( currentSlideCaption.find('.item-caption').html() );
				
				modalThumbs.find(".slick-thumb").removeClass('slick-current');
				modalThumbs.find(".slick-thumb").eq(currentSlide).addClass('slick-current');

				//Osvezimo stevec, caption in aktivne thumbe ko menjamo slide
				modalGallery.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
					var i = (currentSlide ? currentSlide : 0) + 1;
					galleryCounter.text(i + '/' + slick.slideCount);
					//Caption text za slideshowe
					galleryCaptionContainer.html( $(slick.$slides.get(i-1)).find('.item-caption').html() );
					//Oznacimo aktivni thumb
					modalThumbs.find(".slick-thumb").removeClass('slick-current');
					modalThumbs.find(".slick-thumb").eq(i-1).addClass('slick-current');
					modalGalleryHeight();
				});
			}

		});
	});

	//On top gumb
	$("div.ontop").click(function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		return false;
	});

	//Download center floater
	if ( $(".floater").length && $('.floater-start').length) {
		$(".floater .opener").click(function() {
			if ( $( window ).width() <= 992 ) {
				$(".floater").toggleClass("open");
				//console.log("odpiram floater");
			}
		});
	}

	mobileView();
	fixedHeader();
	level2Height();
	setSlide();
	floaterScroll();
	stackedTables();
	//modalGalleryHeight();
	
	//Preveri vnos maila za formo
	function checkMail(value)
	{
		var at = "@";
		var atPosition = value.indexOf(at);
	
		var dot = ".";
		var length = value.length;
	
		if(value.indexOf(at) < 0) { return false; }
		if(value.indexOf(at) < 0 || value.indexOf(at) == 0 || value.indexOf(at) == length -1) { return false; }
		if(value.indexOf(dot) < 0 || value.indexOf(dot) == 0 || value.indexOf(dot) == length -1) { return false; }
		if(value.indexOf(at, (atPosition + 1)) > -1) { return false }
		if(value.substring(atPosition - 1, atPosition) == dot || value.substring(atPosition + 1, atPosition + 2) == dot) { return false; }
		if(value.indexOf(dot, (atPosition + 2)) < 0) { return false; }
		if(value.indexOf(" ") > -1) { return false; }
	
		return true;
	}


	// News Category Switch
	$('.js-catName .sw-option').on('click', function () {
		var current = $(this);
		var catID = current.data('cat');
		$('.js-catName [data-cat]').removeClass('selected');
		$('.js-newsCat [data-cat]').hide();
		$('.js-catName [data-cat="'+ catID +'"]').addClass('selected');
		$('.js-newsCat [data-cat="'+ catID +'"]').show();

		if(current.closest('.js-catName').hasClass('gmapsBox')) {
			initGoogleMaps();
		}
	});
	
	// FileManager
	$(document).on('click', '.js-filemanager .folder', function () {
		var current = $(this);
		var path = current.data('path');
		updateManager(path);
	});

	$(document).on('click', '.parentCategory', function () {
		var path = $('.js-filemanager').attr('data-parentPath');
		updateManager(path);
	});

	function updateManager(path) {
		let startPath = $('.js-loadManager').data('startfolder');
		let homeName = $('.js-loadManager').data('startfoldername');
		$.ajax({
			method:'POST',
			url: 'http://'+window.location.host+'/filemanager/folders/show',
			data: {path, startPath, homeName},
			dataType : "html"
		})
		.done(function (data) {
			$('.js-loadManager').html(data);
		});
	}

	// Google Maps
	initGoogleMaps();
	function initGoogleMaps() {
		$('div[id^="gmap-"]').each(function () {
			let current = $(this);
			let mapID = current.prop('id').split('-');
			mapID = mapID.pop();
			let mapData = current.data('gmaps');
			let mapJSON = current.data('savedlocations');
			let savePath = current.data('savepath');
			this['mapObj'+ mapID] = new SpectoGoogleMap(mapID, savePath);
			this['mapObj'+ mapID]['init'](mapData, mapJSON);
		});
	}

	$("#fileURL").on("click", function () {
		$(this).select();
	});

	// Home Modal
	// if (localStorage.getItem("popup") === null) {
	// 	localStorage.setItem("popup", true);
	// 	$('#homeModal').modal();
	// }

	// Open/Close Audio
	$('.catSection h2').on('click', function () {
		let current = $(this);
		current.toggleClass('down');
		current.closest('.catSection').find('.loadFiles ').slideToggle();
	});
	$('.descriptionBlock .openClose').on('click', function () {
		let current = $(this);
		current.toggleClass('down');
		current.closest('.descriptionBlock').find('.content').slideToggle();
	});

	/* Ajax Pagination => Laravel */
	$(document).on('click', '.ajaxPagination-js a', function (e) {
		e.preventDefault();
		let current = $(this);
		let loadBox = current.closest('.loadFiles');
		let pageID = $('body').data('pageid');
		let widgetID = current.closest('[data-widgetid]').data('widgetid');
		let widgetType = current.closest('[data-widgetid]').data('widgettype');
		let url = current.attr('href');
		getPaginateContent(loadBox, url, pageID, widgetID, widgetType);
	});
	function getPaginateContent(loadBox, url, pageID, widgetID, widgetType) {
		$.ajax({
			url: url,
			data: {pageID, widgetID, widgetType}
		})
		.done(function (data) {
			console.log(data);
			$(loadBox).html(data.html);
			scrollToElement($(loadBox), 500, -100);
		});
	}
	function scrollToElement(element, time = 1000, offset = -40) {
		$('html, body').animate({
			scrollTop: element.offset().top+offset
		}, time);
	}
	/* END */
	
});

$( window ).scroll(function() {

	//On top gumb
	if ($(this).scrollTop() > 300) {
		$('div.ontop').fadeIn();
	} else {
		$('div.ontop').fadeOut();
	}

	//Ostala solata
	mobileView();
	fixedHeader();
	level2Height();
	setSlide();
	floaterScroll();

});

//resize za 200ms zamikom (na ne refresha prepogosto)
function resizeend() {
	if (new Date() - rtime < delta) {
		setTimeout(resizeend, delta);
	} else {
		timeout = false;
		modalGalleryHeight();
		tabsSlick();
	}               
}
var rtime;
var timeout = false;
var delta = 200;

$( window ).resize(function() {

	rtime = new Date();
	if (timeout === false) {
		timeout = true;
		setTimeout(resizeend, delta);
	}

	mobileView();
	fixedHeader();
	level2Height();
	setSlide();
	floaterScroll();
	tabsCollapse();
	stackedTables();
	positionModalWindow();

});


function replaceEditChars(text){
	return text.replace(/&/g, '&amp;').replace(/'/g, '&apos;').replace(/"/g, '&quot;');
}

function returnTextIfExists(obj){
	return $(obj).length > 0 ? $(obj).text() : "";
}


$( document ).ready(function() {

	$('#ClosePopupButton').click(function() {
		
	  $('#PopupWrap').toggle("slide", {direction:'right'}, 500);
		  
	  $('#ShadowedPage').fadeOut("slow");
	  $('#OpenPopupButton').show();
	});	
	
	$('#OpenPopupButton').click(function() {
		$( '#PopupWrap' ).promise().done(function() {
			
		  $('#PopupWrap').toggle("slide", {direction:'right'}, 500);
		  
		  $('#ShadowedPage').fadeIn("slow");
		  $('#OpenPopupButton').hide();
		});
	});	
	
	$('.gallery-items').magnificPopup({
	  delegate: 'a', // child items selector, by clicking on it popup will open
	  type: 'image',
	  removalDelay: 300,
	  mainClass: 'mfp-fade',
	  gallery: {
		  enabled:true,
		  tCounter: '<span class="mfp-counter">%curr% / %total%</span>' // markup of counter
		}
	  // other options
	});

});
function showURL(url){
	$('#fileURL').val(url);
	$('#myModal').modal('toggle');
	return false;
}