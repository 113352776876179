// configuration
var showSettings = true;
var backgroundPath = "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlOZyTXzhgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=)";
var CGPcolor='#df038b;';
var BGcolor='rgb(0, 0, 0)';
var dontAllowBG = 'rgb(170, 15, 15);';
var allowBG = 'rgb(122, 204, 0);';
var borderRadius = '4px 4px 4px 4px';
var textColor = '#ffffff';
var opacity = '1';

// prepare elements
//document.body.innerHTML = "<div id='cookieSettings' style='display: none;'></div><div id='cookieMoreMask' style='display: none;'><div id='cookieMore' style='width: 820px; background: #fff; margin: 0 auto; position: relative; top: 100px; padding: 25px 20px; text-align: left; border-radius: "+borderRadius+"; font-size: 12px; line-height: 19px; box-shadow: 0px 0px 8px 1px;'></div></div><div style='display: none;' id='cookieAlert'></div>"+document.body.innerHTML;

//cookie settings
var div1 = document.createElement("div");
div1.id = "cookieSettings";
div1.style.display = 'none';
document.body.appendChild(div1);
//cookieMore
var div3 = document.createElement("div");
div3.id = "cookieMore";
div3.style.width = "820px";
div3.style.background = "#fff";
div3.style.margin = "0 auto";
div3.style.position = "relative";
div3.style.top = "100px";
div3.style.padding = "25px 20px";
div3.style.textAlign = "left";
div3.style.borderRadius = borderRadius;
div3.style.fontSize = "12px";
div3.style.lineHeight = "19px";
//cookieMoreMask
var div2 = document.createElement("div");
div2.id = "cookieMoreMask";
div2.style.display = "none";
div2.appendChild(div3);
document.body.appendChild(div2);
//cookieAlert
var div4 = document.createElement("div");
div4.id = "cookieAlert";
div4.style.display = "none";
document.body.appendChild(div4);


if(getCookie("allowCookies") == null)
{
    showAlert();    
}
else if(showSettings)
{
    showSettingsAlert();
}

function showSettingsAlert()
{
    var html = "<a href='#' onclick='showMoreWindow(); return false;' style='display: block; color: #fff; width: 100px; height: 20px; line-height: 20px; text-align: center; border-radius: "+borderRadius+"; position: relative; top: 0px;'  onclick='acceptCookie(false); return false;'>Uredi piškotke</a>";
    document.getElementById("cookieSettings").innerHTML = html;
    
    //document.getElementById("cookieSettings").setAttribute("style", "display:block");
    //document.getElementById("cookieSettings").setAttribute("style", "background:  "+BGcolor+"; width: 100px; padding: 10px; color: "+CGPcolor+"; border-radius: "+borderRadius+"; opacity: "+opacity+"; position:fixed; bottom: 10px; left: 10px; z-index: 10000; text-align: left; font-size: 11px;");
    var div = document.getElementById("cookieSettings");
    div.style.display = "block";
    div.style.background = backgroundPath;
    div.style.width = "100px";
    div.style.padding = "10px";
    div.style.color = CGPcolor;
    div.style.borderRadius = borderRadius;
    div.style.opacity = opacity;
    div.style.position = "fixed";
    div.style.bottom = "10px";
    div.style.left = "10px";
    div.style.zIndex = 10000;
    div.style.textAlign = "left";
    div.style.fontSize = "11px";
}


function showAlert()
{
    var html = "<h3 style='color: "+CGPcolor+"; font-size:12px; padding-bottom: 7px;'>Obvestilo o uporabi piškotkov!</h3><p>Spletna stran za svoje pravilno delovanje potrebuje piškotke. Prosimo Vas, da jih sprejmete, saj boste s tem izboljšali uporabniško izkušnjo ob uporabi. <a style='color: "+CGPcolor+"; ' href='#' onclick='showMoreWindow(); return false;'>Več</a></p> <a href='#' class='btn-cc' style='display: block; color: #fff; width: 100px; height: 20px; line-height: 20px; text-align: center; background:"+CGPcolor+"; border-radius: "+borderRadius+"; position: relative; top: 10px;' onclick='acceptCookie(); return false;'>Sprejmem piškotke</a> <br>"; 
    document.getElementById("cookieAlert").innerHTML = html;
    
    //document.getElementById("cookieAlert").setAttribute("style", "display:block");
    //document.getElementById("cookieAlert").setAttribute("style", "background:  "+BGcolor+"; width: 103px; padding: 10px; color: "+textColor+"; border-radius: "+borderRadius+"; opacity: "+opacity+"; position:fixed; bottom: 10px; left: 10px; z-index: 10000; text-align: left;");
    document.getElementById("cookieAlert").style.display = "block";
    var div = document.getElementById("cookieAlert");
    //div.style.background = BGcolor;
    div.style.background = backgroundPath;
    div.style.width = "120px";
    div.style.padding = "10px";
    div.style.color = textColor;
    div.style.borderRadius = borderRadius;
    div.style.opacity = opacity;
    div.style.position = "fixed";
    div.style.bottom = "10px";
    div.style.left = "10px";
    div.style.zIndex = "100000";
    div.style.textAlign = "left";
}

function acceptCookie()
{
    setCookie("allowCookies", "true", 1);
    //document.getElementById("cookieAlert").setAttribute("style", "display:none");
    document.getElementById("cookieAlert").style.display = "none";
    window.location.reload();
    //showSettingsAlert();
}
function removeCookie()
{
    setCookie("allowCookies", "", -1);
    //eraseCookieFromAllPaths("allowCookies");
    window.location.reload();    
}

function showMoreWindow()
{
    var c1_s = "Potrjen";
    var c1_a = "<a style='color: "+CGPcolor+";' href='#' onclick='removeCookie();closeMoreWindow();return false;' style='color:"+CGPcolor+"'>ponastavi</a>";
    var c2_s = "seznanjen";
    var c2_a = "<a style='color: "+CGPcolor+";' href='#' onclick='removeCookie();closeMoreWindow();return false;' style='color:"+CGPcolor+"'>ponastavi</a>";
    
    
    if(getCookie("allowCookies") == null)
    {
        c1_s = "Nepotrjen";
        c1_a = "-";
        c2_s = "neseznanjen";
        c2_a = "-";
    }
    
    var html = "<h3 style='color: "+CGPcolor+"; font-size:17px; padding-bottom: 7px; border-bottom: 2px solid; margin-bottom: 8px;'>Obvestilo o uporabi piškotkov!</h3> <a style='display: block; color: #fff; width: 20px; height: 20px; line-height: 20px; text-align: center; background:"+CGPcolor+"; border-radius: "+borderRadius+"; position: absolute; top: 20px; right: 20px; font-weight: bold;' href='#' onclick='closeMoreWindow();return false;' class='btn-close'>+</a>"+
               "<p style='padding: 10px 0;'>Spletna stran uporablja piškotke za zagotavljanje boljše uporabniške izkušnje, optimizacijo prikaza prilagojenih vsebin in spremljanje statistike obiska. O uporabi piškotkov so uporabniki obveščeni ob prvem obisku spletnih strani, za uporabo nekaterih piškotkov prosimo uporabnika za njegovo privolitev. Uporabnik lahko svojo odločitev v zvezi z uporabo piškotkov kadarkoli spremeni na tej spletni strani. <br /><br />Lastnik spletne strani ne posreduje piškotkov zunanjim ponudnikom storitev in jih ne uporablja za prikazovanje reklamnih sporočil. </p>"+
               "<b style='display: block; font-size:14px; padding: 6px 0 0 0; color: "+CGPcolor+";'>O piškotkih</b>"+
               "<p style='padding: 10px 0;'>Piškotki so majhne tekstovne datoteke, ki jih portal naloži na vaš računalnik. Nekateri piškotki so nujno potrebni za delovanje spletnih strani. Piškotki ne vsebujejo nobenih osebnih ali drugih podatkov, ki bi jih bilo mogoče uporabiti za identifikacijo uporabnika, zato priporočamo, da v njihovo uporabo privolite. </p>"+
               "<b style='display: block; font-size:14px; padding: 6px 0 0 0; color: "+CGPcolor+";'>Uporabljeni piškotki</b>"+
               "<table style='width:100%; margin: 10px 0; font-size: 10px;' cellpadding='5' cellspacing='2'>"+
               "<tr>"+
                   "<td width='60' style='border-bottom: 1px solid #ccc; font-weight: bold;'>Vrsta</td>"+
                   "<td width='130' style='border-bottom: 1px solid #ccc; font-weight: bold;'>Ime</td>"+
                   "<td width='370' style='border-bottom: 1px solid #ccc; font-weight: bold;'>Namen</td>"+
                   "<td width='100' style='border-bottom: 1px solid #ccc; font-weight: bold;'>Iztek</td>"+
                   "<td width='80' style='border-bottom: 1px solid #ccc; font-weight: bold;'>Status</td>"+
                   "<td  style='border-bottom: 1px solid #ccc; font-weight: bold;'>Akcija</td>"+
               "</tr>"+
               
               "<tr>"+ // ACCEPT_COOKIE
                   "<td style='border-bottom: 1px solid #ccc;'>Lastni</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>allowCookies</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>shranjevanje odločitve uporabe piškotkov</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>2 leti</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>"+c1_s+"</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>"+c1_a+"</td>"+
               "</tr>"+       
               "<tr>"+ // PHPSESSION
                   "<td style='border-bottom: 1px solid #ccc;'>Sejni</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>PHPSESSID</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>omogočajo delovanje spletnih strani</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>do konca seje</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>-</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>-</td>"+
               "</tr>"+        
               "<tr>"+ // GOOGLE ANALYTICS
                   "<td style='border-bottom: 1px solid #ccc;'>Zunanji</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>_utma, _utmz,…</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>statistika Google Analytics</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>do 2 leti</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>"+c1_s+"</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>"+c1_a+"</td>"+
               "</tr>"+
                "<tr>"+ // FACEBOOK
                   "<td style='border-bottom: 1px solid #ccc;'>Zunanji</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>datr, locale, …</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>Facebook vtičnik</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>do 2 leti</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>"+c1_s+"</td>"+
                   "<td style='border-bottom: 1px solid #ccc;'>"+c1_a+"</td>"+
               "</tr>"+
               "</table>"+
               
               "<p style='padding: 10px 0;'>Uporabnik lahko svojo privolitev za uporabo posameznih piškotkov umakne s klikom na povezavo (akcijo) 'ponastavi' v zgornji tabeli. Umik privolitve lahko povzroči spremenjeno delovanje nekaterih spletnih strani ali onemogoči uporabo posameznih storitev.</p>"+
               "<b style='display: block; font-size:14px; padding: 6px 0 0 0; color: "+CGPcolor+";'>Upravljanje piškotkov v brskalniku</b>"+
               "<p style='padding: 10px 0;'>Večina spletnih brskalnikov samodejno sprejme piškotke. Veljavnost piškotkov je lahko kratkotrajna ali dolgotrajna, po poteku veljavnosti brskalnik piškotke samodejno izbriše. Uporabnik lahko vse piškotke ali piškotke za posamezno spletišče (domeno) izbriše tudi ročno ali pa uporabo piškotkov v brskalniku v celoti onemogoči. Navodila za izbris piškotkov ali onemogočitev njihove uporabe lahko najdete v sklopu pomoči vašega spletnega brskalnika. V primeru, da boste uporabo piškotkov v celoti onemogočili vam nekatere funkcionalnosti spletnih strani ne bodo delovale pravilno. <br /><br />Junij 2013 </p>";
    
    
    document.getElementById("cookieMore").innerHTML = html;
    document.getElementById("cookieMoreMask").style.display = "block";
    
    //document.getElementById("cookieMoreMask").setAttribute("style", "width: 100%; height: 100%; position: fixed; z-index: 10001; background: "+BGcolor+"; opacity: "+opacity+"; top: 0px; ");
    var div = document.getElementById("cookieMoreMask");
    div.style.width = "100%";
    div.style.height = "100%";
    div.style.position = "fixed";
    div.style.zIndex = "100001";
    div.style.background = backgroundPath;
    div.style.opacity = opacity;
    div.style.top = "0px";
    div.style.left = "0px";
}

function closeMoreWindow()
{
    //document.getElementById("cookieMoreMask").setAttribute("style", "display:none");
    document.getElementById("cookieMoreMask").style.display = "none"; 
}

/****** cookies ********/
function setCookie(c_name,value,exdays)
{
    var exdate=new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
    document.cookie=c_name + "=" + c_value+"; path=/";
}

function eraseCookieFromAllPaths(name) {
    // This function will attempt to remove a cookie from all paths.
    var pathBits = location.pathname.split('/');
    var pathCurrent = ' path=';

    // do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-70 00:00:01 GMT;';

    for (var i = 0; i < pathBits.length; i++) {
        pathCurrent += ((pathCurrent.substr(-1) != '/') ? '/' : '') + pathBits[i];
        document.cookie = name + '=; expires=Thu, 01-Jan-70 00:00:01 GMT;' + pathCurrent + ';';
    }
}

function getCookie(c_name)
{
    var c_value = document.cookie;
    var c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1)
    {
        c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start == -1)
    {
        c_value = null;
    }
    else
    {
        c_start = c_value.indexOf("=", c_start) + 1;
        var c_end = c_value.indexOf(";", c_start);
        if (c_end == -1)
    {
        c_end = c_value.length;
    }
        c_value = unescape(c_value.substring(c_start,c_end));
    }
    return c_value;
}